<template>
  <div>
    <v-form>
      <p class="mb-3">
        Match the letter below the GHS symbol to a type of hazard that it represents.
      </p>

      <v-img
        src="/img/assignments/ChemUCI_1LC_Q1S_Q8_image.png"
        alt="image of a hazard chart"
        style="max-width: 491px"
      />

      <p v-for="(option, index) in options" :key="option" class="mb-1">
        {{ option.letter }}
        <v-select
          :key="option"
          v-model="inputs.studentInputs[index]"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
          style="width: 250px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI_1LC_Q1S_Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentInputs: [],
      },
      options: [
        {
          text: 'Aquatic Toxicity',
          letter: 'A: ',
          value: 'a',
        },
        {
          text: 'Oxidizer',
          letter: 'B: ',
          value: 'b',
        },
        {
          text: 'Flammable',
          letter: 'C: ',
          value: 'c',
        },
        {
          text: 'Explosive',
          letter: 'D: ',
          value: 'd',
        },
        {
          text: 'Corrosive',
          letter: 'E: ',
          value: 'e',
        },
        {
          text: 'Gas under pressure',
          letter: 'F: ',
          value: 'f',
        },
        {
          text: 'Irritant, skin sensitizer',
          letter: 'G: ',
          value: 'g',
        },
        {
          text: 'Carcinogen & toxic',
          letter: 'H: ',
          value: 'h',
        },
        {
          text: 'Fatal',
          letter: 'I: ',
          value: 'i',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
